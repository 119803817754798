// import React, { useState,useEffect } from "react";
// import Carousel from 'react-bootstrap/Carousel';
// import { Link } from 'react-router-dom';
// import '../css/Carousel.css';
// import { useTranslation } from 'react-i18next';
// import { BASE_URL, BASE_URL_PUBLIC } from "./config/keys";
// import axios from 'axios';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css'
// import CustomArrow from './CustomArrow';

// function HomeCarousel() {
//   const { t, i18n } = useTranslation();
//   const [index, setIndex] = useState(0);
//   const [productsCatalogue, setProductsCatalogue] = useState(null);
//   const handleSelect = (selectedIndex, e) => {
//     setIndex(selectedIndex);
//     //console.log(`Selected index: ${selectedIndex}`);
//   };
  
//   const settings = {
//     dots: false,
//     arrows: true,
//     infinite: true,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     prevArrow: <CustomArrow direction="prev" />, // Custom arrow for previous slide
//     nextArrow: <CustomArrow direction="next" />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           infinite: true,
//           dots: false,
//           arrows: true,
//           prevArrow: <CustomArrow direction="prev" />, // Custom arrow for previous slide
//           nextArrow: <CustomArrow direction="next" />,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
  
//   useEffect(()=>{
//       async function fetchData(){
//           try {
//               const response = await axios.get(BASE_URL+'products/catalogue?lang='+i18n.language);
//               setProductsCatalogue(response.data.data);               
//           } catch (error) {
//               console.error('Error fetching data:', error);
//           }
//       }
//       fetchData();
//   },[i18n.language])
//  // console.log(productsCatalogue);

//   return (
//     <>            
//       <div className="container">
//         <div className="row">
//           <Slider {...settings}>
//             {productsCatalogue?.map((slide) => (
//               <div key={slide.id}>
//                 <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">
//                   <div className="" style={{ 
//                       width: "auto", 
//                       backgroundImage:encodeURI(`url(${BASE_URL_PUBLIC+'uploads/photos/articles/'+slide.photo})`) ,
//                       backgroundSize:'contain',    
//                       height: '250px',
//                       backgroundRepeat: 'no-repeat',
//                       backgroundPosition: 'center center'
//                     }}>
//                     <Link to={`/product_detail/${slide.id}`} className="text-decoration-none"></Link>
//                   </div>
//                 </Link>
//                 <div className="card-body">
//                   <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">                      
//                     <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
//                   </Link>
//                   <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>                    
//                   <Link className="btn-outline-primary px-4 mb-2 text-decoration-none float-end"
//                     to={`/product_detail/${slide.id}`} 
//                     style={{ 
//                       color: '#04D9D9', border: '1px solid #04D9D9', borderRadius:'15px' 
//                     }}>
//                     {t('Voir')}
//                   </Link>
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>
//     </>
//   );
// }

// export default HomeCarousel;


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomArrow from "./CustomArrow";
import { BASE_URL, BASE_URL_PUBLIC } from "./config/keys";

function HomeCarousel() {
  const { t, i18n } = useTranslation();
  const [productsCatalogue, setProductsCatalogue] = useState([]);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASE_URL}products/catalogue?lang=${i18n.language}`);
        setProductsCatalogue(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [i18n.language]);

  return (
    <div className="container">
      <div className="row">
        <Slider {...settings}>
          {productsCatalogue?.map((product) => (
            <div key={product.id}>
              <Link
                to={`/${i18n.language}/article/${i18n.language === "fr" ? product.url_fr : product.url_en}`}
                className="text-decoration-none"
              >
                <div
                  style={{
                    width: "auto",
                    backgroundImage: `url(${BASE_URL_PUBLIC}uploads/photos/articles/${product.photo})`,
                    backgroundSize: "contain",
                    height: "250px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  }}
                ></div>
              </Link>
              <div className="card-body">
                <Link
                  to={`/${i18n.language}/article/${i18n.language === "fr" ? product.url_fr : product.url_en}`}
                  className="text-decoration-none"
                >
                  <p className="mb-0 mt-3 fw-bold title_card">{product.title}</p>
                </Link>
                <p dangerouslySetInnerHTML={{ __html: product.petit_description }}></p>
                <Link
                  className="btn-outline-primary px-4 text-decoration-none float-end"
                  to={`/${i18n.language}/article/${i18n.language === "fr" ? product.url_fr : product.url_en}`}
                  style={{
                    color: "#04D9D9",
                    border: "1px solid #04D9D9",
                    borderRadius: "15px",
                  }}
                >
                  {t("Voir")}
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default HomeCarousel;
