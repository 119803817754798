// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
// import { useTranslation } from 'react-i18next';

// function ProductDetailid() {
//     const { id } = useParams(); 
//     const { t } = useTranslation(); 
//     const [product, setProduct] = useState(null); 
//     const [error, setError] = useState(null); 
//     const { i18n } = useTranslation();


//     // async function fetchProduct(productId) {
//     //     try {
//     //         const response = await axios.get(`${BASE_URL}products-by-id/${productId}`);
//     //         setProduct(response.data.data); 
//     //     } catch (err) {
//     //         console.error('Error fetching product:', err);
//     //         setError(t('Failed to load product details.'));
//     //     }
//     // }

//     // Appel de l'API lors du chargement ou du changement de l'ID

//     async function fetchProduct(productId) {
//         try {
//             const lang = localStorage.getItem('i18nextLng') || 'fr'; // Récupère la langue active
//             console.log('Langue actuelle:', lang); // Vérifie la langue
//             const response = await axios.get(`${BASE_URL}products-by-id/${productId}?lang=${lang}`);
//             console.log('URL appelée:', `${BASE_URL}products-by-id/${productId}?lang=${lang}`);
//             setProduct(response.data.data); 
//         } catch (err) {
//             console.error('Error fetching product:', err);
//             setError(t('Failed to load product details.'));
//         }
//     }
    

//     // useEffect(() => {
//     //     if (id) {
//     //         fetchProduct(id);
//     //     }
//     // }, [id,t]);

//     useEffect(() => {
//         console.log('Langue actuelle :', i18n.language);
//         if (id) {
//             fetchProduct(id);
//         }
//     }, [id, i18n.language]);

    
//     if (error) {
//         return <div>{error}</div>;
//     }

//     if (!product) {
//         return <div>{t('Loading...')}</div>;
//     }

import { useTranslation } from 'react-i18next';
import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';


function ProductDetailid() {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    

    async function fetchProduct(productId) {
        try {
            const lang = i18n.language; 
            console.log('Langue utilisée pour l’API :', lang);
            const response = await axios.get(`${BASE_URL}products-by-id/${productId}?lang=${lang}`);
            setProduct(response.data.data);
        } catch (err) {
            console.error('Error fetching product:', err);
            setError(t('Failed to load product details.'));
        }
    }
    

    useEffect(() => {
        console.log('Langue active (i18n.language) :', i18n.language);
        if (id) {
            fetchProduct(id);
        }
    }, [id, i18n.language]); 
     

    if (error) return <div>{error}</div>;
    if (!product) return <div>{t('Loading...')}</div>;

    return (
        <div className="container mb-5 mt-4">
            <div className="col-md-12">
                <section className="panel">
                    <div className="panel-body row ml-3">
                        <div className="col-md-6 col-sm-12" style={{ width: "auto" }}>
                            <div style={{ maxWidth: "400px" }}>
                                {product.photo && (
                                    <img
                                        className="img-fluid img-thumbnail"
                                        src={`${BASE_URL_PUBLIC}uploads/photos/articles/${product.photo}`}
                                        alt={product.title}
                                        style={{ height: "auto" }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 mt-2 ml-0">
                            {product.title && (
                                <h4 className="pro-d-title" style={{ fontSize: '18px' }}>
                                    {product.title}
                                </h4>
                            )}
                            {product.description && (
                                <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ProductDetailid;
