// import React, { useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import '../css/Nav.css';
// import Logo from '../images/logoweb.png';
// import axios from 'axios';
// import { BASE_URL } from './config/keys';
// import { useNavigate } from 'react-router-dom';

// const Nav = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const { t, i18n } = useTranslation();
//     const [language, setLanguage] = useState(i18n.language);
//     const [isOpen, setIsOpen] = useState(false);
//     const [inputText, setInputText] = useState('');
//     const [products, setProducts] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${BASE_URL}/products/all?lang=${i18n.language}`);
//                 setProducts(response.data.data);
//             } catch (error) {
//                 console.error('Error fetching products:', error);
//             }
//         };
//         fetchData();
//     }, [i18n.language]);

//     const handleToggle = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleLinkClick = () => {
//         setIsOpen(false);
//     };

//     const handleInputChange = (event) => {
//         setInputText(event.target.value);
//     };

//     const handleProductClick = (product) => {
//         navigate(`/product_detail/${product.id}`);
//     };

//     const changeLanguage = (lang) => {
//         localStorage.setItem('language', lang);
//         setLanguage(lang);
//         i18n.changeLanguage(lang);
//     };

//     const fetchAllProducts = async () => {
//         try {
//             const response = await axios.get(`${BASE_URL}/products/all`);
//             setProducts(response.data.data);
//             setIsOpen(false); // Ferme le menu déroulant après la sélection
//         } catch (error) {
//             console.error('Error fetching all products:', error);
//         }
//     };

//     const fetchProductsByCategory = async (category) => {
//         try {
//             if (!category) {
//                 // Si aucune catégorie n'est sélectionnée, fetch tous les produits
//                 fetchAllProducts();
//                 return;
//             }
//             const response = await axios.get(`${BASE_URL}/products/category/${category}`);
//             setProducts(response.data.data);
//             setIsOpen(false); // Ferme le menu déroulant après la sélection
//         } catch (error) {
//             console.error(`Error fetching products for category ${category}:`, error);
//         }
//     };

//     return (
//         <>
//             <nav className="navbar navbar-expand-lg w-100">
//                 <div className="container row m-0 p-0" style={{ maxWidth: '100%' }}>
//                     <div className="col-4 pt-0 div_logo">
//                         <div className="d-flex align-items-center flex-wrap" style={{ marginBottom: '-56px' }}>
//                             <Link className="navbar-brand" to="/" style={{ textDecoration: 'none' }}>
//                                 <div className="d-flex align-items-center">
//                                     <img src={Logo} alt="...logo" className="img-responsive" style={{ zoom: '22%', marginTop: '-9%', marginLeft: '15%' }} />
//                                     <div className="div_title_logo">
//                                         <div className="fs-4" style={{ color: '#000059' }}>PRAT DUMAS</div>
//                                         <div style={{ color: '#0074A3' }}>Since 1309</div>
//                                     </div>
//                                 </div>
//                             </Link>
//                         </div>
//                     </div>
//                     <div className="col-8 p-0">
//                         <button
//                             className="navbar-toggler float-end mr-5 mt-1"
//                             type="button"
//                             data-bs-toggle="collapse"
//                             data-bs-target="#navbarSupportedContent"
//                             aria-controls="navbarSupportedContent"
//                             aria-expanded="false"
//                             aria-label="Toggle navigation"
//                             style={{ marginInlineEnd: '15px' }}
//                             onClick={handleToggle}
//                         >
//                             <span className="navbar-toggler-icon"></span>
//                         </button>
//                         <div className={`${isOpen ? '' : 'collapse navbar-collapse'}`} id="navbarSupportedContent">
//                             <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
//                                 <NavItem to="/" text={t('home')} active={location.pathname === '/'} onClick={handleLinkClick} />
//                                 <NavItem to="/products" text={t('products')} active={location.pathname === '/products' || location.pathname.startsWith('/product_detail')} onClick={() => fetchProductsByCategory()} />
//                                 <NavItem to="/part_dumas" text={t('prat_dumas')} active={location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')} onClick={handleLinkClick} />
//                                 <NavItem to="/contact" text={t('contact')} active={location.pathname === '/contact'} onClick={handleLinkClick} />
//                             </ul>
//                             <div className='langue ms-auto me-5'>
//                                 <LanguageSwitcher currentLanguage={i18n.language} onChangeLanguage={changeLanguage} />
//                             </div>
//                         </div>
//                         <div className={`${isOpen ? ' dropdown-menu' : 'd-none'}`} id="navbarSupportedContent">
//                             <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
//                                 <NavItem to="/" text={t('home')} active={location.pathname === '/'} onClick={handleLinkClick} />
//                                 <NavItem to="/products" text={t('products')} active={location.pathname === '/products' || location.pathname.startsWith('/product_detail')} onClick={() => fetchProductsByCategory()} />
//                                 <NavItem to="/part_dumas" text={t('prat_dumas')} active={location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')} onClick={handleLinkClick} />
//                                 <NavItem to="/contact" text={t('contact')} active={location.pathname === '/contact'} onClick={handleLinkClick} />
//                             </ul>
//                             <div className='langue ms-auto me-5'>
//                                 <LanguageSwitcher currentLanguage={i18n.language} onChangeLanguage={changeLanguage} />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="container-fluid d-flex justify-content-end p-2 div_search">
//                     <div className="col-md-4 col-sm-6">
//                         <SearchForm inputText={inputText} onInputChange={handleInputChange} onProductClick={() => handleProductClick(products[0])} placeholder={t('search_placeholder')} />
//                     </div>
//                 </div>
//             </nav>
//         </>
//     );
// };

// const NavItem = ({ to, text, active, onClick }) => (
//     <li className="nav-item">
//         <Link className={`nav-link ${active ? 'active' : ''}`} to={to} style={{ color: active ? 'white' : '#164181', textDecoration: active ? 'underline' : 'none' }} onClick={onClick}>
//             {text}
//         </Link>
//     </li>
// );

// const LanguageSwitcher = ({ currentLanguage, onChangeLanguage }) => (
//     <div className='d-flex'>
//         <Link className={`nav-link ${currentLanguage === 'en' ? 'text-white' : ''}`} to="#" onClick={() => onChangeLanguage('en')}>EN</Link> / <Link className={`nav-link ${currentLanguage === 'fr' ? 'text-white' : ''}`} to="#" style={{ color: '#164181' }} onClick={() => onChangeLanguage('fr')}>FR</Link>
//     </div>
// );

// const SearchForm = ({ inputText, onInputChange, onProductClick, placeholder }) => (
//     <div className="form-group has-search d-flex">
//         <input type="text" className="form-control custom-input" value={inputText} onChange={onInputChange} placeholder={placeholder} />
//         <span role='button' className="input-group-text ml-auto" id="basic-addon1" style={{ backgroundColor: '#38A3BC', color: 'white', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={onProductClick}>
//             <svg className="bi bi-search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                 <path fillRule="evenodd" d="M10.832 9.48a5.5 5.5 0 1 0-1.352 1.352l3.704 3.704a1 1 0 0 0 1.415-1.415l-3.704-3.704zM2 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0z" />
//             </svg>
//         </span>
//     </div>
// );

// export default Nav;



import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../css/Nav.css';
import Logo from '../images/logoweb.png';
import axios from 'axios';
import { BASE_URL } from './config/keys';
import { useNavigate } from 'react-router-dom';

const Nav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const [isOpen, setIsOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchData();
    }, [i18n.language]);

    const fetchData = async () => {
        try {
            console.log('BASE_URL:', BASE_URL);
            const response = await axios.get(`${BASE_URL}products?lang=${i18n.language}`);
                        setProducts(response.data.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleProductClick = (product) => {
        navigate(`/product_detail/${product.id}`);
    };

    const changeLanguage = (lang) => {
        localStorage.setItem('language', lang);
        setLanguage(lang);
        i18n.changeLanguage(lang);
    };

    const fetchAllProducts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/products/all`);
            setProducts(response.data.data);
            setIsOpen(false); 
        } catch (error) {
            console.error('Error fetching all products:', error);
        }
    };

    const fetchProductsByCategory = async (category) => {
        try {
            if (!category) {
                fetchAllProducts();
                return;
            }
            const response = await axios.get(`${BASE_URL}/products/category/${category}`);
            setProducts(response.data.data);
            setIsOpen(false); 
        } catch (error) {
            console.error(`Error fetching products for category ${category}:`, error);
        }
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg w-100">
                <div className="container row m-0 p-0" style={{ maxWidth: '100%' }}>
                    <div className="col-4 pt-0 div_logo">
                        <div className="d-flex align-items-center flex-wrap" style={{ marginBottom: '-56px' }}>
                            <Link className="navbar-brand" to="/" style={{ textDecoration: 'none' }}>
                                <div className="d-flex align-items-center">
                                    <img src={Logo} alt="...logo" className="img-responsive" style={{ zoom: '22%', marginTop: '-9%', marginLeft: '15%' }} />
                                    <div className="div_title_logo">
                                        <div className="fs-4" style={{ color: '#000059' }}>PRAT DUMAS</div>
                                        <div style={{ color: '#0074A3' }}>Since 1309</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-8 p-0">
                        <button
                            className="navbar-toggler float-end mr-5 mt-1"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            style={{ marginInlineEnd: '15px' }}
                            onClick={handleToggle}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`${isOpen ? '' : 'collapse navbar-collapse'}`} id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
                                <NavItem to="/" text={t('home')} active={location.pathname === '/'} onClick={handleLinkClick} />
                                <NavItem to="/products" text={t('products')} active={location.pathname === '/products' || location.pathname.startsWith('/product_detail')} onClick={() => fetchProductsByCategory()} />
                                <NavItem to="/part_dumas" text={t('prat_dumas')} active={location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')} onClick={handleLinkClick} />
                                <NavItem to="/contact" text={t('contact')} active={location.pathname === '/contact'} onClick={handleLinkClick} />
                            </ul>
                            <div className='langue ms-auto me-5'>
                                <LanguageSwitcher currentLanguage={i18n.language} onChangeLanguage={changeLanguage} />
                            </div>
                        </div>
                        <div className={`${isOpen ? ' dropdown-menu' : 'd-none'}`} id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
                                <NavItem to="/" text={t('home')} active={location.pathname === '/'} onClick={handleLinkClick} />
                                <NavItem to="/products" text={t('products')} active={location.pathname === '/products' || location.pathname.startsWith('/product_detail')} onClick={() => fetchProductsByCategory()} />
                                <NavItem to="/part_dumas" text={t('prat_dumas')} active={location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')} onClick={handleLinkClick} />
                                <NavItem to="/contact" text={t('contact')} active={location.pathname === '/contact'} onClick={handleLinkClick} />
                            </ul>
                            <div className='langue ms-auto me-5'>
                                <LanguageSwitcher currentLanguage={i18n.language} onChangeLanguage={changeLanguage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid d-flex justify-content-end p-2 div_search">
                    <div className="col-md-4 col-sm-6">
                        <SearchForm inputText={inputText} onInputChange={handleInputChange} onProductClick={() => handleProductClick(products[0])} placeholder={t('search_placeholder')} />
                    </div>
                </div>
            </nav>
        </>
    );
};

const NavItem = ({ to, text, active, onClick }) => (
    <li className="nav-item">
        <Link className={`nav-link ${active ? 'active' : ''}`} to={to} style={{ color: active ? 'white' : '#164181', textDecoration: active ? 'underline' : 'none' }} onClick={onClick}>
            {text}
        </Link>
    </li>
);

const LanguageSwitcher = ({ currentLanguage, onChangeLanguage }) => (
    <div className='d-flex'>
        <Link className={`nav-link ${currentLanguage === 'en' ? 'text-white' : ''}`} to="#" onClick={() => onChangeLanguage('en')}>EN</Link> / <Link className={`nav-link ${currentLanguage === 'fr' ? 'text-white' : ''}`} to="#" style={{ color: '#164181' }} onClick={() => onChangeLanguage('fr')}>FR</Link>
    </div>
);

const SearchForm = ({ inputText, onInputChange, onProductClick, placeholder }) => (
    <div className="form-group has-search d-flex">
        <input type="text" className="form-control custom-input" value={inputText} onChange={onInputChange} placeholder={placeholder} />
        <span role='button' className="input-group-text ml-auto" id="basic-addon1" style={{ backgroundColor: '#38A3BC', color: 'white', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={onProductClick}>
            <svg className="bi bi-search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M10.832 9.48a5.5 5.5 0 1 0-1.352 1.352l3.704 3.704a1 1 0 0 0 1.415-1.415l-3.704-3.704zM2 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0z" />
            </svg>
        </span>
    </div>
);

export default Nav;




// import React, { useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import '../css/Nav.css';
// import Logo from '../images/logoweb.png';
// import { BASE_URL, BASE_URL_PUBLIC } from "./config/keys";
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';


// const Nav = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const { t, i18n } = useTranslation();     
//     const [language, setLanguage] = useState(i18n.language);     

//     const changeLanguage = (lang) => {
//         localStorage.setItem('language', lang);
//         setLanguage(lang)
//         i18n.changeLanguage(lang);
//     };

//     const [isOpen, setIsOpen] = useState(false);

//     const handleToggle = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleLinkClick = () => {
//         setIsOpen(false);
//       };

      
//     const [inputText, setInputText] = useState('');
//     const [products, setProducts] = useState(null);    
//     const [suggestions, setSuggestions] = useState([]);

//     const handleInputChange = (event) => {
//         const inputValue = event.target.value;
//         setInputText(inputValue);
//         console.log(products)
//         const filteredSuggestions = products.filter(product => {
//             console.log('Input Value:', inputValue);
//             console.log('Title FR:', product.title_fr);
//             console.log('Title EN:', product.title_en);
          
//             return (
//               product.title_fr.toLowerCase().includes(inputValue.toLowerCase()) ||
//               product.title_en.toLowerCase().includes(inputValue.toLowerCase())
//             );
//           });
//         console.log(filteredSuggestions);
//         setSuggestions(filteredSuggestions);

//     };
    
//     useEffect(()=>{
//         console.log('Language changed:', language);
//         const DataProduct = async () => {
//             try {
//                 const response = await axios.get(BASE_URL+'products/catalogue?lang='+i18n.language);
//                 setProducts(response.data.data);               
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         }
//         DataProduct();
//     },[i18n.language])

//     const handleProductClick = (product) => {
//         setInputText('')
//         navigate(`/product_detail/${product.id}`);
//     };


    

//     return (
//         <>
//             <nav className="navbar navbar-expand-lg w-100">
//                 <div className="container row m-0 p-0" style={{ maxWidth: '100%' }}>
//                     <div className="col-4 pt-0 div_logo">
//                         <div className=" d-flex align-items-center flex-wrap" style={{marginBottom: '-56px' }}>
//                             <Link className="navbar-brand" to="/" style={{ textDecoration: 'none' }}>
//                                 <div className="d-flex align-items-center">
//                                     <img src={Logo} alt='...logo' className="img-responsive"
//                                         style={{ zoom: '22%', marginTop: '-9%', marginLeft: '15%' }}                                        
//                                     />
//                                     <div className="div_title_logo">
//                                         <div className='fs-4' style={{ color: '#000059' }}>PRAT DUMAS</div>
//                                         <div style={{ color: '#0074A3'}}>Since 1309</div>
//                                         {/* {t('home')} {i18n.language} */}
//                                     </div>
//                                 </div>
//                             </Link>
//                         </div>
//                     </div>
//                     <div className="col-8 p-0">
//                         <button
//                             className="navbar-toggler float-end mr-5 mt-1"
//                             type="button"
//                             data-bs-toggle="collapse"
//                             data-bs-target="#navbarSupportedContent"
//                             aria-controls="navbarSupportedContent"
//                             aria-expanded="false"
//                             aria-label="Toggle navigation"  
//                             style={{ marginInlineEnd: '15px' }}  
//                             onClick={handleToggle}                           
//                         >
//                             <span className="navbar-toggler-icon"></span>
//                         </button>
//                         <div className={`${isOpen ? 'd-none' : 'collapse navbar-collapse'}`} /* className="collapse navbar-collapse" */ id="navbarSupportedContent">                           
//                             <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
//                                 <li className="nav-item">
//                                     <Link className="nav-link active" to="/" style={{ color: location.pathname === '/' ? 'white' : '#164181', textDecoration: location.pathname === '/' ? 'underline' : 'none' }} onClick={handleLinkClick}>
//                                         {t('home')}
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item"><Link className="nav-link" to="/products" style={{ color: (location.pathname === '/products' || location.pathname.startsWith('/product_detail')) ? 'white' : '#164181', textDecoration: (location.pathname === '/products' || location.pathname.startsWith('/product_detail')) ? 'underline' : 'none' }} onClick={handleLinkClick}>{t('products')}</Link></li>
//                                 <li className="nav-item"><Link className="nav-link" to="/part_dumas" style={{ color: (location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')) ? 'white' : '#164181', textDecoration: (location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')) ? 'underline' : 'none' }} onClick={handleLinkClick}>{t('prat_dumas')}</Link></li>
//                                 <li className="nav-item"><Link className="nav-link" to="/contact" style={{ color: location.pathname === '/contact' ? 'white' : '#164181', textDecoration: location.pathname === '/contact' ? 'underline' : 'none' }} onClick={handleLinkClick}>{t('contact')} </Link></li>
//                             </ul>
//                             <div className='langue ms-auto me-5'>
//                                 <div className='d-flex'>
//                                 <Link className={i18n.language == 'en' ? "nav-link text-white" : "nav-link"} to="#" onClick={() => changeLanguage('en')}>EN </Link> / <Link className={i18n.language == 'fr' ? "nav-link text-white" : "nav-link"} to="#" style={{ color: '#164181' }} onClick={() => changeLanguage('fr')}> FR</Link>
//                                 </div>
//                             </div>
//                         </div>
                       
//                         <div className={`${isOpen ? ' dropdown-menu' : ' d-none'}`}  id="navbarSupportedContent">                           
//                             <ul className="navbar-nav mb-2 mb-lg-0" style={{ fontFamily: 'Open Sans', fontSize: '12.5pt' }}>
//                                 <li className="nav-item">
//                                     <Link className="nav-link active" to="/" style={{ color: location.pathname === '/' ? 'white' : '#164181', textDecoration: location.pathname === '/' ? 'underline' : 'none' }} onClick={handleLinkClick}>
//                                         {t('home')}
//                                     </Link>
//                                 </li>
//                                 <li className="nav-item"><Link className="nav-link" to="/products" style={{ color: (location.pathname === '/products' || location.pathname.startsWith('/product_detail')) ? 'white' : '#164181', textDecoration: (location.pathname === '/products' || location.pathname.startsWith('/product_detail')) ? 'underline' : 'none' }} onClick={handleLinkClick}>{t('products')}</Link></li>
//                                 <li className="nav-item"><Link className="nav-link" to="/part_dumas" style={{ color: (location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')) ? 'white' : '#164181', textDecoration: (location.pathname === '/part_dumas' || location.pathname.startsWith('/prat_dumas_detail')) ? 'underline' : 'none' }} onClick={handleLinkClick}>{t('prat_dumas')}</Link></li>
//                                 <li className="nav-item"><Link className="nav-link" to="/contact" style={{ color: location.pathname === '/contact' ? 'white' : '#164181', textDecoration: location.pathname === '/contact' ? 'underline' : 'none' }} onClick={handleLinkClick}>{t('contact')}</Link></li>
//                             </ul>
//                             <div className='langue ms-auto me-5'>
//                                 <div className='d-flex'>
//                                 <Link className={i18n.language == 'en' ? "nav-link text-white" : "nav-link"} to="#" onClick={() => changeLanguage('en')}>EN </Link> / <Link className={i18n.language == 'fr' ? "nav-link text-white" : "nav-link"} to="#" style={{ color: '#164181' }} onClick={() => changeLanguage('fr')}> FR</Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="container-fluid d-flex justify-content-end p-2 div_search">
//                     <div className="col-md-4 col-sm-6">
//                         <div className="form-group has-search d-flex">
//                         <input type="text" list='suggestions' className="form-control custom-input" value={inputText} onChange={handleInputChange} placeholder={t('search_placeholder')} />                                                                                             

//                             <span role='button' className="input-group-text ml-auto" id="basic-addon1" style={{ backgroundColor: '#38A3BC', color: 'white', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => handleProductClick(suggestions[0])}>
//                                 <svg className="bi bi-search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
//                                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"></path>
//                                 </svg>
//                             </span>
//                             {suggestions.length > 0 && (
//                                 <datalist id="suggestions">
//                                     {suggestions.map((product) => (
//                                         <option key={product.id}>{product.title}</option>                                        
//                                     ))}
//                                 </datalist>
//                             )}
//                         </div>                        
//                     </div>
//                 </div>
//             </nav>
//         </>
//     )
// }
// export default Nav;